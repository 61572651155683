import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { Button, LoadingTransition, Skeleton } from "..";
// theme
import { theme } from "../../../theme";
// utils
import { cubicBezier } from "../../../utils/easing";
const SIZES = {
    sm: 250,
    md: 300,
};
const COLORS = {
    orange: theme.color.primary[30],
    green: theme.color.primary[80],
};
const bezierEase = cubicBezier(0.65, 0, 0.35, 1); // Define the cubic Bezier curve
const duration = 660; // Animation duration in ms
/**
 * ProgressCircle shows a progress indicator in the form of a circle.
 */
export const ProgressCircle = ({ className, size = 'md', limit, progress, title, description, to, color = 'orange', }) => {
    // state
    const [currentProgress, setCurrentProgress] = useState(0);
    // memos
    const progressPercent = useMemo(() => {
        if (progress === undefined || limit === undefined || limit === 0) {
            return undefined;
        }
        return Math.round((progress / limit) * 100);
    }, [progress, limit]);
    // effects
    useEffect(() => {
        if (progressPercent === undefined)
            return;
        const start = performance.now();
        const initialProgress = Math.min(currentProgress, progressPercent);
        const step = (timestamp) => {
            const elapsed = timestamp - start;
            if (elapsed < duration && currentProgress < progressPercent) {
                const t = Math.min(elapsed / duration, 1); // Normalized time [0, 1]
                const easedProgress = bezierEase(t); // Apply Bezier easing
                setCurrentProgress(initialProgress + easedProgress * (progressPercent - initialProgress));
                requestAnimationFrame(step);
            }
            else {
                setCurrentProgress(progressPercent);
            }
        };
        requestAnimationFrame(step);
    }, [progressPercent]);
    return (_jsx(LoadingTransition, { isLoading: progressPercent === undefined, loading: _jsx(Skeleton, { width: `${SIZES[size]}px`, height: `${SIZES[size]}px` }), content: _jsx(Wrapper, Object.assign({ className: className, "$size": size, "$limit": limit, "$progress": progress, "$color": color, style: { '--progress': `${currentProgress}%` } }, { children: _jsxs(Body, { children: [_jsx(Title, Object.assign({ "$size": size }, { children: title })), _jsxs(Progress, Object.assign({ "$size": size }, { children: [progressPercent, "%"] })), _jsx(Description, Object.assign({ "$size": size }, { children: description })), _jsx(Button, Object.assign({ ariaLabel: 'continue', size: 'sm', to: to }, { children: "Continue" }))] }) })) }));
};
const Wrapper = styled.div `
  position: relative;
  background: ${(props) => props.$progress === props.$limit
    ? COLORS[props.$color]
    : props.theme.color.neutral[10]};
  border-radius: 99999px;
  padding: ${(props) => props.theme.spacing[16]};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: conic-gradient(
      ${(props) => COLORS[props.$color]} 0%,
      ${(props) => COLORS[props.$color]} calc(var(--progress, 0%) - 5%),
      transparent var(--progress, 0%),
      transparent 100%
    );
    border-radius: 99999px;
    z-index: -1;
  }
  ${(props) => {
    let size = SIZES[props.$size];
    return css `
      min-width: ${size}px;
      min-height: ${size}px;
      width: ${size}px;
      height: ${size}px;
      max-width: ${size}px;
      max-height: ${size}px;
    `;
}}

  transition: background 660ms cubic-bezier(0.65, 0, 0.35, 1);
  z-index: 5;
  box-shadow: ${(props) => props.theme.elevation[3]};
`;
const Body = styled.div `
  display: flex;
  background: ${(props) => props.theme.color.neutral[20]};
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 99999px;
  z-index: 10;
`;
const Title = styled.div `
  font: ${(props) => {
    switch (props.$size) {
        case 'sm':
            return props.theme.typography.body.lg.semibold;
        case 'md':
        default:
            return props.theme.typography.body.xl.semibold;
    }
}};
  color: ${(props) => props.theme.color.neutral[100]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
  text-align: center;
`;
const Progress = styled.div `
  font: ${(props) => {
    switch (props.$size) {
        case 'sm':
            return props.theme.typography.body.xl.semibold;
        case 'md':
        default:
            return props.theme.typography.body.xxl.semibold;
    }
}};
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;
`;
const Description = styled.div `
  font: ${(props) => {
    switch (props.$size) {
        case 'sm':
            return props.theme.typography.body.md.regular;
        case 'md':
        default:
            return props.theme.typography.body.lg.regular;
    }
}};
  color: ${(props) => props.theme.color.neutral[100]};
  padding-bottom: ${(props) => props.theme.spacing[24]};
  text-align: center;
`;
ProgressCircle.displayName = 'ProgressCircle';
export default ProgressCircle;
