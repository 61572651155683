// react
import { useCallback, useMemo, useState } from 'react';

// components
import { Dialog, ProfilePreview, Switch, TablePage } from '@karehero/llama';

// store
import { useGetAllInvitedMemberQuery } from 'store/api/invitedMember';
import { useReinviteMemberMutation } from 'store/api/organization';
import { InvitedMember } from '@karehero/models';
import { newToast } from 'store/slices/toastSlice';
import { useDispatch } from 'react-redux';

// tanstack
import { createColumnHelper } from '@tanstack/react-table';

// columns
const columnHelper = createColumnHelper<InvitedMember>();

const columns = [
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('firstName', {
    header: 'First Name',
  }),
  columnHelper.accessor('tier.name', {
    header: 'Tier',
  }),
];

/**
 * ManageInvitedMembers shows the invited members.
 */
const ManageInvitedMembers = () => {
  // state
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);
  const [selectedProfileID, setSelectedProfileID] = useState<string | null>(
    null,
  );
  const [filterKareHeroEmail, setFilterKareHeroEmail] = useState(false);

  // hooks
  const { data: invitedMembers } = useGetAllInvitedMemberQuery();
  const [reinviteMember] = useReinviteMemberMutation();
  const dispatch = useDispatch();

  // memos
  const selectedProfile = useMemo(() => {
    if (!selectedProfileID || !invitedMembers) return null;
    return invitedMembers.find(
      (invitedMember) => invitedMember.id === selectedProfileID,
    );
  }, [selectedProfileID, invitedMembers]);

  const rows = useMemo(() => {
    if (!invitedMembers) return [];
    return invitedMembers
      .filter((invitedMember) => {
        if (!filterKareHeroEmail) return true;
        return !invitedMember.email.toLowerCase().includes('@karehero.com');
      })
      .map((invitedMember) => ({
        ...invitedMember,
      }));
  }, [invitedMembers, filterKareHeroEmail]);

  const selectedProfileInitials = useMemo(() => {
    if (!selectedProfile) return '';
    return (selectedProfile?.firstName?.[0] || '').toUpperCase();
  }, [selectedProfile]);

  // methods
  const handleReinviteMember = useCallback(
    async (id?: string) => {
      if (!id) return;
      const res = await reinviteMember(id);

      if ('error' in res) {
        console.error(res.error);
        return;
      }
      dispatch(
        newToast({
          title: 'Invitation Success',
          description: `Resent invitation to ${selectedProfile?.email}`,
        }),
      );
      setIsProfilePreviewOpen(false);
    },
    [dispatch, selectedProfile, reinviteMember],
  );

  return (
    <>
      <TablePage
        title='Invited Members'
        columns={columns}
        rows={rows}
        rowsPerPage={10}
        actions={[
          {
            label: 'View',
            onPress: (invitedMember: InvitedMember) => {
              setSelectedProfileID(invitedMember.id);
              setIsProfilePreviewOpen(true);
            },
          },
        ]}
        toolbarActions={
          <Switch
            id='filterKareHeroEmail'
            value={filterKareHeroEmail}
            onChange={setFilterKareHeroEmail}
            label='Filter KareHero Emails'
            size='sm'
            isNoWrap
          />
        }
      />
      <Dialog
        variant='sheet'
        isOpen={isProfilePreviewOpen}
        setIsOpen={setIsProfilePreviewOpen}
        zIndex={1}
      >
        <ProfilePreview
          fallback={selectedProfileInitials}
          name={`${selectedProfile?.firstName || ''}`}
          role={selectedProfile?.role?.name}
          tier={selectedProfile?.tier?.name}
          email={selectedProfile?.email}
          onClose={() => setIsProfilePreviewOpen(false)}
          actions={[
            {
              label: 'Reinvite',
              onPress: () => handleReinviteMember(selectedProfile?.id),
            },
          ]}
          isActionsDisabled
        />
      </Dialog>
    </>
  );
};

export default ManageInvitedMembers;
