// rtk query
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// sentry
import * as Sentry from '@sentry/react';

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8085';
export const kindeEnvironmentUrl =
  process.env.REACT_APP_KINDE_ENVIRONMENT_URL || '';
export const kindeClientId = process.env.REACT_APP_KINDE_CLIENT_ID || '';
export const sanityProjectId = 'x7r1t5o8';
export const sanityDataset = 'production';

/**
 * baseQueryWithErrorHandling is a wrapper around fetchBaseQuery that logs
 * errors to Sentry.
 */
const baseQueryWithErrorHandling = async (
  args: any,
  api: any,
  extraOptions: any,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/v1/`,
    credentials: 'include',
  });
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) Sentry.captureException(result.error);

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: () => ({}),
  tagTypes: [
    'Accounts',
    'AccountRoles',
    'ActionPlanCategory',
    'ActionPlan',
    'ActionPlanFeedback',
    'ArticleBookmarks',
    'CareCircles',
    'CareProfileCategories',
    'CarePlan',
    'Chat',
    'InvitedMembers',
    'OrganizationMembers',
    'Organizations',
    'Personalization',
    'Relationships',
    'Tiers',
    'CareHomeShortlist',
    'CareHomeShortlistData',
    'AdminCareHomeShortlist',
    'AdminCareHomeShortlistData',
    'HubspotIdentity',
  ],
});

export default api;
