import { FieldType } from './enums';
import { validationStringToMethod } from "../utils";
// Given a subquestion and the answer of its parent, this checks whether the subquestion is relevant
export const isSubQuestionRelevant = (subQuestion, parentAnswer) => {
    if (parentAnswer === undefined)
        return false;
    if (parentAnswer === true)
        parentAnswer = 'true';
    if (parentAnswer === false)
        parentAnswer = 'false';
    let conditionValue = subQuestion.conditionValue;
    switch (subQuestion.conditionOperator) {
        case 'equal':
            return parentAnswer === conditionValue;
        case 'not-equal':
            return parentAnswer !== conditionValue;
        case 'contains':
            return parentAnswer.includes(conditionValue);
        case 'in':
            return !!conditionValue && conditionValue.includes(parentAnswer);
        default:
            return true;
    }
};
export const listAllSubQuestions = (questions) => {
    if (!questions || questions.length === 0)
        return [];
    const subQuestions = questions.reduce((acc, question) => {
        return [...acc, ...(question.careProfileSubQuestions || [])];
    }, []);
    return [...questions, ...listAllSubQuestions(subQuestions || [])];
};
export const listAllSubQuestionsOfForm = (form) => listAllSubQuestions(form.careProfileQuestions);
export const listAllQuestionsOfSubCategory = (subCategory) => {
    var _a;
    return (_a = subCategory === null || subCategory === void 0 ? void 0 : subCategory.careProfileForms) === null || _a === void 0 ? void 0 : _a.reduce((acc, form) => [
        ...acc,
        ...listAllSubQuestionsOfForm(form),
    ], []);
};
export const formFromCategory = (category, formID) => {
    let form = null;
    category.careProfileSubCategories.forEach((subc) => {
        subc.careProfileForms.forEach((f) => {
            if (f.id === formID) {
                form = f;
                return;
            }
        });
        if (form !== null)
            return;
    });
    return form;
};
export const answersFromMap = (careProfileId, answers) => {
    return Object.keys(answers).map((questionId) => ({
        careProfileId,
        value: answers[questionId],
        careProfileQuestionId: questionId,
    }));
};
export const tableFromQuestion = (question) => {
    var _a;
    if (!question.fieldTable) {
        return {};
    }
    const splitTable = (_a = question.fieldTable) === null || _a === void 0 ? void 0 : _a.split(';');
    return {
        title: splitTable[0],
        columns: splitTable[1].split(','),
        rows: splitTable[2].split(','),
    };
};
export const categoryByID = (categories, id) => categories === null || categories === void 0 ? void 0 : categories.find((category) => category.id === (id || 'care-plan'));
export const subQuestionsFromCategory = (category) => {
    var _a;
    return (_a = ((category === null || category === void 0 ? void 0 : category.careProfileSubCategories) || [])) === null || _a === void 0 ? void 0 : _a.reduce((acc, subCategory) => [
        ...acc,
        ...listAllQuestionsOfSubCategory(subCategory),
    ], []);
};
export const answersFromQuestions = (questions) => {
    const res = {};
    questions.forEach((q) => {
        if (q.answerValue !== undefined) {
            res[q.id] = q.answerValue;
        }
    });
    return res;
};
export const buildForm = (form, answers) => {
    let fields = {};
    let order = 0;
    const assignField = (question) => {
        var _a;
        const table = tableFromQuestion(question);
        order += 10;
        const mapField = (question) => {
            var _a, _b, _c, _d, _e;
            const labels = {};
            if (question.fieldLabels) {
                question.fieldLabels.split(';').forEach((label) => {
                    const labelSplit = label.split(':');
                    labels[labelSplit[0]] = labelSplit[1];
                });
            }
            return {
                id: question.id,
                label: question.label,
                supportText: question === null || question === void 0 ? void 0 : question.supportText,
                isRequired: question.isRequired,
                placeholder: question.placeholder,
                tooltip: question.tooltip,
                validation: validationStringToMethod(question.validation),
                field: {
                    labels,
                    table,
                    type: question.fieldType,
                    // size: 'sm', // TODO: check this?
                    limits: {
                        min: parseInt((_b = (_a = question.fieldLimits) === null || _a === void 0 ? void 0 : _a.split(',')) === null || _b === void 0 ? void 0 : _b[0]),
                        max: parseInt((_d = (_c = question.fieldLimits) === null || _c === void 0 ? void 0 : _c.split(',')) === null || _d === void 0 ? void 0 : _d[1]),
                    },
                    options: (_e = question.fieldOptions) === null || _e === void 0 ? void 0 : _e.map((option) => ({
                        value: option.id,
                        label: option.label,
                        order: option.order,
                    })).sort((a, b) => a.order - b.order),
                    // TODO: check this .sort((a: any, b: any) => a.label.localeCompare(b.label)),
                    isCustom: question.isCustom,
                },
            };
        };
        const mappedField = mapField(question);
        fields[question.id] = Object.assign(Object.assign({}, mappedField), { order, field: Object.assign(Object.assign({}, mappedField.field), { subfields: question.fieldType === FieldType.QuestionTable
                    ? (_a = question.careProfileSubQuestions) === null || _a === void 0 ? void 0 : _a.map(mapField)
                    : undefined }) });
    };
    const questions = form.careProfileQuestions || [];
    questions === null || questions === void 0 ? void 0 : questions.forEach((question) => {
        assignField(question);
        const createSubQuestions = (q, val) => {
            var _a, _b, _c;
            if (q.fieldType === FieldType.QuestionTable)
                return;
            (_c = (_b = (_a = [...(q.careProfileSubQuestions || [])]) === null || _a === void 0 ? void 0 : _a.sort((a, b) => a.order - b.order)) === null || _b === void 0 ? void 0 : _b.filter((subQ) => isSubQuestionRelevant(subQ, val))) === null || _c === void 0 ? void 0 : _c.forEach((subQ) => {
                assignField(subQ);
                createSubQuestions(subQ, answers === null || answers === void 0 ? void 0 : answers[subQ.id]);
            });
        };
        createSubQuestions(question, answers === null || answers === void 0 ? void 0 : answers[question.id]);
    });
    return {
        id: form.id,
        title: form.title,
        description: form.description,
        prevFormID: form.prevFormID,
        nextFormID: form.nextFormID,
        progress: form.progress,
        fields,
        isComplete: questions === null || questions === void 0 ? void 0 : questions.every((question) => ((answers === null || answers === void 0 ? void 0 : answers[question.id]) !== undefined ||
            question.isRequired === false) &&
            (answers === null || answers === void 0 ? void 0 : answers[question.id]) !== ''),
    };
};
export const buildForms = (category, answers) => {
    const res = [];
    ((category === null || category === void 0 ? void 0 : category.careProfileSubCategories) || []).forEach((subCategory) => {
        subCategory.careProfileForms.forEach((form) => {
            res.push(buildForm(form, answers));
        });
    });
    return res;
};
export const buildSections = (category) => (category.careProfileSubCategories || []).map((element) => {
    var _a, _b;
    return ({
        id: element.id,
        title: element.title,
        firstFormID: element.firstFormID,
        finalFormID: element.finalFormID,
        subSections: element.careProfileForms.map((form) => {
            var _a, _b, _c;
            return ({
                title: form.title,
                id: form.id,
                isComplete: ((_a = form.progress) === null || _a === void 0 ? void 0 : _a.isComplete) || false,
                to: `/${element.careProfileCategoryId}/${form.id}`,
                sectionStatus: ((_b = form.progress) === null || _b === void 0 ? void 0 : _b.isComplete)
                    ? 'complete'
                    : ((_c = form.progress) === null || _c === void 0 ? void 0 : _c.isInProgress)
                        ? 'inProgress'
                        : 'notStarted',
            });
        }),
        sectionStatus: ((_a = element.progress) === null || _a === void 0 ? void 0 : _a.isComplete)
            ? 'complete'
            : ((_b = element.progress) === null || _b === void 0 ? void 0 : _b.isInProgress)
                ? 'inProgress'
                : 'notStarted',
    });
});
