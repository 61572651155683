// react
import { Navigate } from 'react-router-dom';

import { useGetCareProfileSubmissionQuery } from 'store/api/careProfile';
import { useCareProfile } from 'hooks';

interface ProtectedFormStartProps {
  categoryId: string;
  Component: React.ComponentType;
}

// Protected route component, reusable for any form start page that needs this protection or redirect
const ProtectedFormStart = ({
  categoryId,
  Component,
}: ProtectedFormStartProps) => {
  const { careCircle, toFirstFormPage } = useCareProfile({
    categoryID: categoryId,
  });

  const { data: formStatus } = useGetCareProfileSubmissionQuery({
    careProfileCategoryId: categoryId,
    careProfileId: careCircle?.careProfile?.id || '',
  });

  const isFormStarted =
    formStatus?.actionType === 'started' ||
    formStatus?.actionType === 'submitted';

  return isFormStarted ? (
    <Navigate to={toFirstFormPage} replace />
  ) : (
    <Component />
  );
};

ProtectedFormStart.displayName = 'ProtectedFormStart';
export default ProtectedFormStart;
