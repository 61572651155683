/**
 * validationStringToMethod converts a validation string to a validation method.
 */
export const validationStringToMethod = (validationsString) => {
    if (!validationsString) {
        return () => true;
    }
    const validations = validationsString.split(',');
    return (value) => {
        return validations.every((validationString) => {
            if (!value)
                return true;
            const v = validationString.split(':');
            const method = v[0];
            const arg = v[1];
            // only used in date validations
            let argDate = new Date(arg);
            if (arg === 'today') {
                argDate = new Date();
                argDate.setHours(0, 0, 0, 0);
            }
            switch (method) {
                case 'min-length':
                    return String(value).length >= Number(arg);
                case 'max-length':
                    return String(value).length <= Number(arg);
                case 'min':
                    return Number(value) >= Number(arg);
                case 'max':
                    return Number(value) <= Number(arg);
                case 'password':
                    return (String(value).length >= 8 &&
                        String(value).match(/[0-9]/) &&
                        String(value).match(/[a-z]/) &&
                        String(value).match(/[A-Z]/));
                case 'email':
                    return String(value)
                        .toLowerCase()
                        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                case 'before':
                    return new Date(String(value)) < argDate;
                case 'after':
                    return new Date(String(value)) > argDate;
                case 'before-or-on':
                    return new Date(String(value)) <= argDate;
                case 'after-or-on':
                    return new Date(String(value)) >= argDate;
                case 'nhs-number':
                    return String(value).match(/^[0-9]{10}$/);
                case 'national-insurance-number':
                    return String(value).match(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/);
                case 'phone':
                    return String(value).match(/^\+?[0-9]{10,15}$/);
                case 'postcode':
                    return String(value).match(/\b(?:[A-Za-z][A-HJ-Ya-hj-y]?[0-9][0-9A-Za-z]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})\b/);
                case 'sjp-partner-code':
                    return String(value).match(/^[0-9]{6}[A-z]{1}$/);
                case 'name':
                    return String(value).match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/);
                case 'disallowed-domains':
                    const disallowedDomains = String(arg).toLowerCase().split(',');
                    return !disallowedDomains.some((domain) => String(value).endsWith(domain.toLowerCase()));
                default:
                    return true;
            }
        });
    };
};
