// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

// components
import { Avatar, Dialog, ProfilePreview, TablePage } from '@karehero/llama';
import { createFieldHelper, Editor } from '@karehero/llama';

// store
import { useDispatch } from 'react-redux';
import { useGetAllQuery } from 'store/api/careCircle';
import { Account, CareHomeShortlist, FieldType } from '@karehero/models';
import { setCareCircleId } from 'store/slices/careCircleSlice';
import {
  useCreateShortlistMutation,
  useGetShortlistByCareProfileIdQuery,
  useUpdateShortlistMutation,
} from 'store/api/shortlist';
import { newToast } from 'store/slices/toastSlice';

// tanstack
import { createColumnHelper } from '@tanstack/react-table';

// columns
interface CareCircleAccount extends Account {
  careCircleId: string;
  careProfileId: string;
}
const columnHelper = createColumnHelper<CareCircleAccount>();

const shortlistFields = createFieldHelper<CareHomeShortlist>({
  shortlistURL: {
    label: 'Shortlist URL',
    isRequired: true,
    field: {
      type: FieldType.Text,
    },
    validationRules: [
      {
        type: 'min-length',
        value: 2,
      },
    ],
  },
  shortlistNote: {
    label: 'Shortlist Note',
    isRequired: true,
    field: {
      type: FieldType.Text,
    },
    validationRules: [
      {
        type: 'min-length',
        value: 2,
      },
    ],
  },
});

const columns = [
  columnHelper.accessor('imgSrc', {
    header: '',
    cell: (info) => {
      const row = info.cell.row.original;
      const fallback =
        `${row.firstName?.[0] || ''}${row.lastName?.[0] || ''}`.toUpperCase();
      return <Avatar size='xs' fallback={fallback} />;
    },
  }),
  columnHelper.accessor('fullName', {
    header: 'Name',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('kindeEmail', {
    header: 'Kinde Email',
  }),
];

/**
 * ManageCareCircles is the dashboard page.
 */
const ManageCareCircles = () => {
  // state
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] =
    useState<CareCircleAccount | null>(null);
  const [isUpdateShortlistFormOpen, setIsUpdateShortlistFormOpen] =
    useState(false);
  const [shortlist, setShortlist] = useState<Partial<CareHomeShortlist>>({});

  // hooks
  const { data: careCircles } = useGetAllQuery();
  const dispatch = useDispatch();

  const { data: careHomeShortlist } = useGetShortlistByCareProfileIdQuery(
    selectedProfile?.careProfileId || '',
    { skip: !selectedProfile },
  );
  const [createShortlist] = useCreateShortlistMutation();
  const [updateShortlist] = useUpdateShortlistMutation();

  // effects
  useEffect(() => {
    if (!selectedProfile) return;

    if (selectedProfile.careProfileId !== careHomeShortlist?.careProfileID) {
      setShortlist({});
      return;
    }

    setShortlist(careHomeShortlist);
  }, [selectedProfile, careHomeShortlist, setShortlist]);

  // memos
  const rows = useMemo(() => {
    return careCircles?.map((careCircle) => ({
      ...careCircle.primaryCaregiverAccount,
      careCircleId: careCircle.id,
      careProfileId: careCircle.careProfile?.id,
    }));
  }, [careCircles]);

  const selectedProfileInitials = useMemo(() => {
    if (!selectedProfile) return '';
    return (
      (selectedProfile?.firstName?.[0].toUpperCase() || '') +
      (selectedProfile?.lastName?.[0].toUpperCase() || '')
    );
  }, [selectedProfile]);

  const handleShortlist = useCallback(
    async (profile: CareCircleAccount) => {
      if (!profile) return;

      const res = shortlist.id
        ? await updateShortlist({
            ...shortlist,
            id: shortlist.id,
            careProfileID: profile.careProfileId,
          })
        : await createShortlist({
            ...shortlist,
            careProfileID: profile.careProfileId,
          });

      if ('error' in res) {
        console.error(res.error);
        dispatch(
          newToast({
            title: 'Error',
            description: 'An error occurred while processing the shortlist.',
            variant: 'error',
          }),
        );
        return;
      }

      dispatch(
        newToast({
          title: shortlist.id ? 'Shortlist Updated' : 'Shortlist Created',
          description: shortlist.id
            ? 'Shortlist successfully updated'
            : 'Shortlist successfully created',
        }),
      );

      setIsUpdateShortlistFormOpen(false);
    },
    [
      shortlist,
      setIsUpdateShortlistFormOpen,
      dispatch,
      updateShortlist,
      createShortlist,
    ],
  );

  return (
    <>
      <TablePage
        title='Manage Care Circles'
        columns={columns}
        rows={rows}
        actions={[
          {
            label: 'View',
            onPress: (careCircle: CareCircleAccount) => {
              setSelectedProfile(careCircle);
              setIsProfilePreviewOpen(true);
            },
          },
          {
            label: 'Manage Shortlist',
            onPress: (careCircle: CareCircleAccount) => {
              setSelectedProfile(careCircle);
              setIsUpdateShortlistFormOpen(true);
            },
          },
        ]}
      />
      <Dialog
        variant='sheet'
        isOpen={isProfilePreviewOpen}
        setIsOpen={setIsProfilePreviewOpen}
      >
        <ProfilePreview
          fallback={selectedProfileInitials}
          name={`${selectedProfile?.firstName || ''} ${
            selectedProfile?.lastName || ''
          }`}
          email={selectedProfile?.email}
          toViewFullProfile='/'
          onViewFullProfile={() => {
            dispatch(setCareCircleId(selectedProfile?.careCircleId));
          }}
          onClose={() => setIsProfilePreviewOpen(false)}
        />
      </Dialog>
      <Dialog
        variant='dialog'
        isOpen={isUpdateShortlistFormOpen}
        setIsOpen={setIsUpdateShortlistFormOpen}
        title='Add shortlist URL'
        description={`${selectedProfile?.email}`}
        zIndex={2}
      >
        <FormWrapper>
          <Editor
            onSave={() => {
              if (!selectedProfile) return;
              handleShortlist(selectedProfile);
            }}
            onCancel={() => setIsUpdateShortlistFormOpen(false)}
            saveLabel='Save'
            cancelLabel='Cancel'
            isWithArrows={false}
            isActionsDisabled={false}
            value={shortlist}
            onChange={(val) => setShortlist(val)}
            fields={shortlistFields}
            isDisabled={false}
            isFloatingActions={true}
            isForm={true}
            isSaveDisabled={false}
          />
        </FormWrapper>
      </Dialog>
    </>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;

export default ManageCareCircles;
