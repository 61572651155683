// react
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// hooks
import { useCareProfile } from 'hooks';

// components
import {
  CareProfileHero as CareProfileHeroPage,
  CareProfileRoot,
} from '@karehero/llama';

/**
 * CareProfileStart is the dashboard page.
 */
const CareProfileStart = () => {
  // hooks
  const {
    sections,
    category,
    handlePrintPdf,
    careRecipientFirstName,
    toFirstFormPage,
    title,
  } = useCareProfile({ categoryID: 'care-assessment' });
  const navigate = useNavigate();

  return (
    <StyledCareProfileRoot
      nextLabel='Create care assessment'
      onPrintPdf={handlePrintPdf}
      title={title}
      limit={category?.progress?.requiredCount || 0}
      progress={category?.progress?.answeredCount || 0}
      sections={sections}
      name={careRecipientFirstName}
      toExit='/care-profile'
      onNext={() => navigate(toFirstFormPage)}
      toPrevious='/care-profile'
    >
      <CareProfileHeroPage />
    </StyledCareProfileRoot>
  );
};

const StyledCareProfileRoot = styled(CareProfileRoot)`
  overflow: hidden;
`;

export default CareProfileStart;
