// react
import { Middleware } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { track, MixpanelEvent } from 'hooks/useMixpanel';

/**
 * localStorage middleware ensures localStorage always has a serialized version
 * of the store. This is useful for persisting state between page refreshes, as
 * well as for implementing an offline-first strategy.
 */
export const localStorageMiddleware: Middleware<{}, RootState> =
  ({ getState }) =>
  (next) =>
  (action) => {
    const result = next(action);
    const state = getState();
    const tmpState = { ...state, api: undefined };
    localStorage.setItem('reduxState', JSON.stringify(tmpState));
    return result;
  };

enum ResponseType {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Invalid = 'invalid',
}

const getResponseType = (action: any): ResponseType => {
  if (typeof action?.type !== 'string') {
    return ResponseType.Invalid;
  }

  if (action.type.toLowerCase().includes('rejected')) {
    return ResponseType.Rejected;
  } else if (action.type.toLowerCase().includes('fulfilled')) {
    return ResponseType.Fulfilled;
  }

  return ResponseType.Invalid;
};

const anonymizeMixpanelEvent = (action: any, responseType: ResponseType) => {
  action = JSON.parse(JSON.stringify(action));
  switch (responseType) {
    case ResponseType.Fulfilled:
      delete action?.payload;
      break;
    case ResponseType.Rejected:
      delete action?.payload?.data;
      break;
  }

  delete action?.meta?.arg?.originalArgs;
  return action;
};

export const mixpanelTrackingMiddleware: Middleware<{}, RootState> =
  () => (next) => (action) => {
    let responseType = getResponseType(action);
    if (responseType === ResponseType.Invalid) return next(action);

    const anonymizedAction = anonymizeMixpanelEvent(action, responseType);
    track(MixpanelEvent.APIRequestFromPWA, {
      action: anonymizedAction,
    });

    return next(action);
  };

/**
 * preloadState deserializes the store from localStorage and returns it.
 */
export const preloadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }

    let state = JSON.parse(serializedState);

    state.toast.toastQueue = [];

    return state;
  } catch (err) {
    return undefined;
  }
};
