var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Keni } from "../../atoms";
import { OnboardingEditor } from "..";
import { OnboardingActionType, OnboardingFlow } from "../../pages";
// validation
const validateNotEmpty = (value) => (value && value.length > 0) ||
    (typeof value === 'boolean' && value !== undefined);
/**
 * EligibilityCalculator calculates whether a user is eligible for attendance
 * allowance.
 */
export const EligibilityCalculator = ({ className, fields, answers, setAnswers, onUpdateCareProfile, onEligibilityStatusChange, }) => {
    // state
    const [isRetake, setIsRetake] = useState(false);
    // refs
    const wrapperRef = useRef(null);
    // methods
    const scrollIntoView = useCallback(() => {
        const el = wrapperRef.current;
        if (!el)
            return true;
        el.scrollIntoView({ behavior: 'smooth' });
        return true;
    }, [wrapperRef]);
    const handleComplete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield onUpdateCareProfile();
        setIsRetake(false);
    }), [onUpdateCareProfile]);
    // memos
    const eligibilityConfig = useMemo(() => {
        return [
            {
                id: 'care-profile-questions',
                Component: OnboardingEditor,
                onBeforeNext: () => __awaiter(void 0, void 0, void 0, function* () { return scrollIntoView(); }),
                config: [
                    ...Object.keys(fields || {}).map((id, i) => {
                        const f = fields === null || fields === void 0 ? void 0 : fields[id];
                        return {
                            id: f.id,
                            title: f.label,
                            subTitle: f.supportText,
                            tooltip: f.tooltip,
                            value: { value: answers === null || answers === void 0 ? void 0 : answers[f.id] },
                            onChange: (value) => {
                                setAnswers(Object.assign(Object.assign({}, answers), { [f.id]: value.value }));
                            },
                            size: 'sm',
                            fieldDef: Object.assign(Object.assign({}, f), { isHideTitle: true, validation: validateNotEmpty, isRequired: false }),
                            actions: [
                                ...(i !== 0
                                    ? [
                                        {
                                            label: 'Back',
                                            onClick: OnboardingActionType.Back,
                                        },
                                    ]
                                    : []),
                                {
                                    label: 'Next',
                                    onClick: OnboardingActionType.Next,
                                },
                            ],
                        };
                    }),
                ],
            },
        ];
    }, [answers, fields, setAnswers, onUpdateCareProfile, wrapperRef]);
    const eligibilityStatus = useMemo(() => {
        if (isRetake)
            return 'incomplete';
        const ans = {
            location: answers['care-recipient-location'],
            isOver65: answers['care-recipient-is-over-65'],
            healthCondition: answers['care-recipient-health-condition'],
            previousSupervision: answers['care-recipient-previous-supervision'],
            terminallyIll: answers['care-recipient-terminally-ill'],
            helpNeeded: answers['care-recipient-help-needed'],
        };
        for (let i = 0; i < Object.keys(ans).length; i++) {
            const key = Object.keys(ans)[i];
            const val = ans[key];
            if (!val) {
                return 'incomplete';
            }
        }
        // location
        const isInEngland = ans.location === 'care-recipient-location-england';
        // state pension age
        const isStatePensionAge = ans.isOver65 === 'true';
        // health condition
        const isHealthCondition = ans.healthCondition === 'true';
        // previous supervision
        const isPreviousSupervision = ans.previousSupervision === 'true';
        // terminally ill
        const isTerminallyIll = ans.terminallyIll === 'true';
        // help needed
        const isHelpNeeded = JSON.parse(ans.helpNeeded || '[]').length > 0;
        // eligibility
        const eligibilityCount = [
            isHealthCondition,
            isPreviousSupervision,
            isTerminallyIll,
            isHelpNeeded,
        ].filter((x) => x).length;
        // eligibility conditions
        if (!isInEngland)
            return 'not-eligible';
        if (!isStatePensionAge)
            return 'not-eligible';
        if (eligibilityCount == 1)
            return 'eligible';
        if (eligibilityCount > 1)
            return 'very-eligible';
        return 'not-eligible';
    }, [answers, isRetake]);
    const eligibilityRender = useMemo(() => {
        switch (eligibilityStatus) {
            case 'very-eligible':
            case 'eligible':
            case 'not-eligible':
                const isEligible = eligibilityStatus !== 'not-eligible';
                return (_jsxs(Result, { children: [_jsx(KeniWrapper, { children: _jsx(KeniBorder, { children: _jsx(Keni, { emotion: isEligible ? 'happy' : 'sad', keniWidth: '100px' }) }) }), _jsxs("div", { children: [eligibilityStatus === 'very-eligible' &&
                                    'From our calculations, it looks like you are very likely to be eligible to claim for Attendance Allowance', eligibilityStatus === 'eligible' &&
                                    'From our calculations, it looks like you may be eligible to claim for Attendance Allowance', eligibilityStatus === 'not-eligible' &&
                                    'From our calculations it doesn’t look like you’re eligible to claim Attendance Allowance'] }), _jsx(Button, Object.assign({ ariaLabel: 'retake the test', variant: 'secondary', size: 'sm', iconLeft: 'rotate-right', onPress: () => setIsRetake(true) }, { children: "Retake the test" }))] }));
        }
        return null;
    }, [eligibilityStatus]);
    useEffect(() => {
        onEligibilityStatusChange && onEligibilityStatusChange(eligibilityStatus);
    }, [eligibilityStatus, onEligibilityStatusChange]);
    return (_jsxs(Wrapper, Object.assign({ ref: wrapperRef }, { children: [eligibilityRender, eligibilityStatus === 'incomplete' && (_jsx(OnboardingFlow, { className: className, config: eligibilityConfig, onComplete: handleComplete, onNext: scrollIntoView, isActionBackground: false }))] })));
};
const Wrapper = styled.div `
  scroll-margin-top: ${(props) => props.theme.spacing[32]};
`;
const Result = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  justify-content: center;
  align-items: center;
  text-align: center;
  font: ${(props) => props.theme.typography.body.lg.regular};
  padding: ${(props) => props.theme.spacing[32]};
`;
const KeniWrapper = styled.div `
  border-radius: 999999px;
  width: 120px;
  max-width: 120px;
  height: 120px;
  max-height: 120px;
  overflow: hidden;
  padding: ${(props) => props.theme.spacing[8]};
  background: ${(props) => props.theme.color.primary[80]};
`;
const KeniBorder = styled.div `
  border-radius: 999999px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.color.custom.fadeUp};
`;
EligibilityCalculator.displayName = 'EligibilityCalculator';
export default EligibilityCalculator;
