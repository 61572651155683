// react
import { useCallback } from 'react';

// components
import { FormIntro as CarePlanStartPage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';
import { useStartCareProfileFormMutation } from 'store/api/careProfile';

/**
 * CarePlan shows the CarePlanPage component.
 */
const CarePlan = () => {
  // hooks
  const { careCircle, toFirstFormPage } = useCareProfile({
    categoryID: 'care-assessment',
  });

  const [startCareProfileForm] = useStartCareProfileFormMutation();

  // method
  const handleNext = useCallback(() => {
    careCircle?.careProfile?.id &&
      startCareProfileForm({
        careProfileCategoryId: 'care-assessment',
        careProfileId: careCircle.careProfile.id,
        actionType: 'started',
      });
  }, [careCircle, startCareProfileForm]);

  return (
    <CarePlanStartPage
      introPageItems={[
        {
          icon: 'file-contract',
          text: 'Answer care and health questions about the person requiring care',
          iconColor: 'blue' as 'blue',
        },
        {
          icon: 'check-circle',
          text: 'Our experts will review and research suitable care services',
          iconColor: 'success' as 'success',
        },
        {
          icon: 'home',
          text: "We'll send you a Shortlist of services, and help you secure your top choice",
          iconColor: 'primary' as 'primary',
        },
      ]}
      introCardDetails={
        "We've helped thousands of families claim funding they didn't realise they were eligible for"
      }
      title={'Get expert help to secure the best care service'}
      toAssistance={'/book-call'}
      toForm={toFirstFormPage}
      onNext={handleNext}
    />
  );
};

export default CarePlan;
