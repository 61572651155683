import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// components
import { Accordion, Button, Title } from "../../atoms";
import { BackButton } from "../../molecules";
import { Editor } from "../Editor/Editor";
/**
 * RecipientProfileAbout shows the profile of the care recipient.
 */
export const RecipientProfileAbout = ({ className, name, keyInformationForm, nextOfKinForm, professionalContactsForm, answers, setAnswers, onSave, isEditing, isModify = false, }) => {
    // memos
    const SaveButton = useMemo(() => {
        if (!isModify) {
            return null;
        }
        return (_jsx(StyledButton, Object.assign({ variant: isEditing ? 'primary' : 'secondary', onPress: onSave, ariaLabel: isEditing ? 'save' : 'edit' }, { children: isEditing ? 'Save' : 'Edit' })));
    }, [isEditing, onSave, isModify]);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsx(BackButton, {}), _jsx(Title, { children: "Key Information" }), _jsxs(SupportText, { children: [`${name || 'Your loved one'}'s`, " key information for you to share with others in the support network when needed."] }), _jsx(Accordion, { items: [
                    {
                        id: 'key-information',
                        label: 'Key Information',
                        content: keyInformationForm && (_jsxs(_Fragment, { children: [SaveButton, _jsx(Editor, { fields: keyInformationForm, isActionsDisabled: true, isFloatingActions: false, value: answers, isDisabled: !isEditing, onChange: (val, key) => setAnswers === null || setAnswers === void 0 ? void 0 : setAnswers(Object.assign(Object.assign({}, answers), { [key]: val[key] })) })] })),
                    },
                    {
                        id: 'next-of-kin',
                        label: 'Next of Kin',
                        content: nextOfKinForm && (_jsxs(_Fragment, { children: [SaveButton, _jsx(Editor, { fields: nextOfKinForm, isActionsDisabled: true, isFloatingActions: false, value: answers, isDisabled: !isEditing, onChange: (val, key) => setAnswers === null || setAnswers === void 0 ? void 0 : setAnswers(Object.assign(Object.assign({}, answers), { [key]: val[key] })) })] })),
                    },
                    {
                        id: 'professional-contacts',
                        label: 'Professional Contacts',
                        content: professionalContactsForm && (_jsxs(_Fragment, { children: [SaveButton, _jsx(Editor, { fields: professionalContactsForm, isActionsDisabled: true, isFloatingActions: false, value: answers, isDisabled: !isEditing, onChange: (val, key) => setAnswers === null || setAnswers === void 0 ? void 0 : setAnswers(Object.assign(Object.assign({}, answers), { [key]: val[key] })) })] })),
                    },
                ] })] })));
};
const Wrapper = styled.div `
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const SupportText = styled.div `
  color: ${(props) => props.theme.color.neutral[80]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const StyledButton = styled(Button) `
  margin-top: ${(props) => props.theme.spacing[24]};
  margin-left: auto;
`;
RecipientProfileAbout.displayName = 'RecipientProfileAbout';
export default RecipientProfileAbout;
