import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { useMemo } from 'react';
import { Card, Container, Icon } from "../../atoms";
import { completionStepsArray } from './fixtures';
export const SuccessContent = ({ fixtures, header, cardHeader, isComplete, }) => {
    const contentFixtures = useMemo(() => (isComplete ? fixtures : completionStepsArray), [isComplete]);
    return (_jsxs(Container, Object.assign({ size: 'md' }, { children: [_jsx(Wrapper, { children: _jsxs(WrapperHeader, { children: [_jsx(ColoredIcon, { icon: 'clipboard-check', fontSize: 50, iconColor: 'success' }), _jsxs(Header, { children: [header, " ", isComplete ? 'Ready For Review' : 'Needs Completing'] })] }) }), _jsxs(ContentCard, { children: [_jsx(ContentHeader, { children: cardHeader }), _jsx("table", { children: contentFixtures.map(({ icon, text, iconColor }, index) => (_jsxs("tr", { children: [isComplete ? (_jsx(IconCell, { children: _jsx(ColoredIcon, { icon: icon, iconColor: iconColor, fontSize: 30 }) })) : (_jsxs(TextCell, { children: [index + 1, "."] })), _jsx(TextCell, { children: text })] }, index))) })] })] })));
};
const WrapperHeader = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
  margin-top: ${(props) => props.theme.spacing[20]};
  margin-bottom: ${(props) => props.theme.spacing[16]};
`;
const ProgressHeader = styled.div `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['3xl']};
  text-align: center;
`;
const Header = styled(ProgressHeader) `
  color: ${(props) => props.theme.color.neutral[100]};
`;
const ContentHeader = styled(ProgressHeader) `
  text-align: left;
  color: ${(props) => props.theme.color.neutral[90]};
`;
const ContentCard = styled(Card) `
  padding: ${(props) => props.theme.spacing[32]};
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const ColoredIcon = styled(Icon) `
  color: ${(props) => props.iconColor === 'orange'
    ? props.theme.color.primary[30]
    : props.iconColor === 'blue'
        ? props.theme.color.actionPlanCards.blue.primary
        : props.iconColor === 'success'
            ? props.theme.color.success[40]
            : props.theme.color.primary[70]};
`;
const TextCell = styled.td `
  font: ${(props) => props.theme.typography.body.md.regular};
  padding: ${(props) => props.theme.spacing[12]};
`;
const IconCell = styled.td `
  padding-right: ${(props) => props.theme.spacing[12]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  gap: ${(props) => props.theme.spacing[16]};
  margin: ${(props) => props.theme.spacing[12]};
`;
SuccessContent.displayName = 'SuccessContent';
export default SuccessContent;
