// react
import { useCallback } from 'react';

// components
import {
  Button,
  FormIntro as AttendanceAllowanceStartPage,
} from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';
import { useStartCareProfileFormMutation } from 'store/api/careProfile';

/**
 * AttendanceAllowance shows the AttendanceAllowancePage component
 */
const AttendanceAllowance = () => {
  // hooks
  const { careCircle, toFirstFormPage } = useCareProfile({
    categoryID: 'attendance-allowance',
  });

  const [startCareProfileForm] = useStartCareProfileFormMutation();

  // method
  const handleNext = useCallback(() => {
    careCircle?.careProfile?.id &&
      startCareProfileForm({
        careProfileCategoryId: 'attendance-allowance',
        careProfileId: careCircle.careProfile.id,
        actionType: 'started',
      });
  }, [careCircle, startCareProfileForm]);

  return (
    <AttendanceAllowanceStartPage
      introPageItems={[
        {
          icon: 'file-contract',
          text: 'Complete our in-app form with personal assistance from your care expert',
          iconColor: 'blue' as 'blue',
        },
        {
          icon: 'print',
          text: (
            <div>
              We'll print your information onto{' '}
              <Button
                ariaLabel='view gov form'
                variant='link'
                isInline
                target='_blank'
                to={
                  'https://assets.publishing.service.gov.uk/media/65e5b1e63f694514a3036001/aa1-interactive-claim-form.pdf'
                }
              >
                this government form
              </Button>
              , and post it to you for signing.
            </div>
          ),
          iconColor: 'success' as 'success',
        },
        {
          icon: 'envelope',
          text: 'All you need to do is post the form to the government using our free post envelope provided',
          iconColor: 'primary' as 'primary',
        },
      ]}
      introCardDetails={
        'Your in-app form is prefilled by your care assessment to save you time, and also checked by care experts to help make a successful application'
      }
      title={'Claim Attendance Allowances to help cover your costs'}
      toAssistance={'/book-call?category=attendance-allowance'}
      toForm={toFirstFormPage}
      onNext={handleNext}
    />
  );
};

export default AttendanceAllowance;
