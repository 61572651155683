import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { FieldType } from '@karehero/models';
// components
import { Button, ButtonSocial, Container, Divider, Title, } from "../../atoms";
import { MemphisHeader } from "../../molecules";
import { EditorReveal } from "../../organisms";
import { HorizontalSplit } from "../../templates";
import { createFieldHelper } from "../../organisms/EditorFields/fieldHelper";
/**
 * Register is the page where the user can sign in.
 */
export const Register = ({ onGoogleRegister, onFacebookRegister, onAppleRegister, onEmailRegister, }) => {
    return (_jsx(HorizontalSplit, { topContent: _jsx(MemphisHeader, {}), bottomContent: _jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(ContentWrapper, { children: _jsx(StyledTitle, { children: "Complete your registration" }) }), _jsx(ButtonContainer, { children: _jsxs(ButtonSocialContainer, { children: [onAppleRegister && (_jsx(ButtonSocial, Object.assign({ variant: 'apple', ariaLabel: 'register with Apple', onPress: onAppleRegister, isFullWidth: true }, { children: "Register with Apple" }))), onGoogleRegister && (_jsx(ButtonSocial, Object.assign({ variant: 'google', ariaLabel: 'register with Google', onPress: onGoogleRegister, isFullWidth: true }, { children: "Register with Google" }))), onFacebookRegister && (_jsx(ButtonSocial, Object.assign({ variant: 'facebook', ariaLabel: 'register with Facebook', onPress: onFacebookRegister, isFullWidth: true }, { children: "Register with Facebook" }))), (onGoogleRegister ||
                                    onFacebookRegister ||
                                    onAppleRegister) && _jsx(Divider, { color: 'dark' }), _jsx(EditorReveal, { onSubmit: (value) => onEmailRegister(value === null || value === void 0 ? void 0 : value.email), revealLabel: 'Register with personal email', submitLabel: 'Register', revealVariant: 'link', submitVariant: 'primary', fields: createFieldHelper({
                                        email: {
                                            label: 'Personal email',
                                            placeholder: 'personal.email@example.com',
                                            isRequired: true,
                                            validationRules: [{ type: 'email' }],
                                            field: {
                                                type: FieldType.Email,
                                                autoFocus: true,
                                            },
                                        },
                                    }) })] }) }), _jsxs("div", { children: ["You can read our privacy policy", ' ', _jsx(Button, Object.assign({ ariaLabel: 'privacy policy', to: 'https://www.karehero.com/privacy-policy', variant: 'link', target: '_blank', isInline: true }, { children: "here" }))] })] }) })) }));
};
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.cards.yellow.tertiary};
  overflow-y: auto;
  height: 100%;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[8]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[32]} 0;
`;
const StyledTitle = styled(Title) `
  text-align: center;
  font: ${(props) => props.theme.typography.heading['5xl']};
  margin: 0;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
    `)}
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[48]};
  margin-bottom: ${(props) => props.theme.spacing[64]};
`;
const ButtonSocialContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[12]};
`;
Register.displayName = 'Register';
export default Register;
