import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useMemo, useState } from 'react';
// components
import { Dialog } from "../../atoms";
import { createFieldHelper, Editor } from "../../organisms";
import { TablePage } from '../TablePage/TablePage';
// models
import { FieldType } from '@karehero/models';
// tanstack
import { createColumnHelper } from '@tanstack/react-table';
// columns
const columnHelper = createColumnHelper();
const columns = [
    columnHelper.accessor('id', {
        header: 'ID',
    }),
    columnHelper.accessor('title', {
        header: 'Title',
    }),
    columnHelper.accessor('sanitySlug', {
        header: 'Sanity Slug',
    }),
    columnHelper.accessor('route', {
        header: 'Route',
    }),
];
/**
 * ActionPlanTable is the dashboard page.
 */
export const ActionPlanTable = ({ isEditable = true, actionPlanItems, updateActionPlan, }) => {
    // state
    const [selectedActionPlanItem, setSelectedActionPlanItem] = useState({});
    const [isActionPlanItemEditorOpen, setIsActionPlanItemEditorOpen] = useState(false);
    // methods
    const handleSave = useCallback(() => {
        updateActionPlan === null || updateActionPlan === void 0 ? void 0 : updateActionPlan(Object.assign({}, selectedActionPlanItem));
        setIsActionPlanItemEditorOpen(false);
    }, [selectedActionPlanItem, updateActionPlan]);
    // memo
    const actions = useMemo(() => {
        return [
            {
                label: 'Edit',
                onPress: (tierSeat) => {
                    setSelectedActionPlanItem(Object.assign({}, tierSeat));
                    setIsActionPlanItemEditorOpen(true);
                },
            },
        ];
    }, []);
    const fields = useMemo(() => {
        return createFieldHelper({
            sanitySlug: {
                label: 'Sanity Slug',
                field: {
                    type: FieldType.Text,
                },
            },
            route: {
                label: 'Route',
                field: {
                    type: FieldType.Text,
                },
            },
        });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(TablePage, { title: `Action Plan Items`, columns: columns, rows: actionPlanItems || [], actions: isEditable ? actions : [], toolbarActions: [] }), _jsx(Dialog, Object.assign({ title: 'Edit Action Plan Item', isCloseButton: true, isOpen: isActionPlanItemEditorOpen, setIsOpen: setIsActionPlanItemEditorOpen }, { children: _jsx(Editor, { onSave: handleSave, onCancel: () => setIsActionPlanItemEditorOpen(false), saveLabel: 'Save', cancelLabel: 'Cancel', isWithArrows: false, isActionsDisabled: false, value: selectedActionPlanItem, onChange: (res) => {
                        setSelectedActionPlanItem((prev) => (Object.assign(Object.assign({}, prev), res)));
                    }, fields: fields, isFloatingActions: true, isForm: true }) }))] }));
};
ActionPlanTable.displayName = 'ActionPlanTable';
export default ActionPlanTable;
