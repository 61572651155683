// react
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { CareCircleMemberAll } from '@karehero/llama';

// hooks
import { useCareCircle } from 'hooks';
import { useGetAllRelationshipQuery } from 'store/api/relationship';

// store
import { dispatch } from 'store';
import { useInviteCareCircleMutation } from 'store/api/careCircle';
import { newToast } from 'store/slices/toastSlice';

// models
import { CareCircleMemberInvite } from '@karehero/models';

/**
 * CareCircleMember shows the CareCircleMemberPage component.
 */
const CareCircleMember = () => {
  // hooks
  const { careCircle } = useCareCircle();
  const [inviteCareCircle] = useInviteCareCircleMutation();
  const navigate = useNavigate();
  const { data: relationships } = useGetAllRelationshipQuery();

  // memos
  const relationshipOptions = useMemo(
    () =>
      relationships?.map((relationship) => ({
        value: relationship.id,
        label: relationship.name,
      })),
    [relationships],
  );

  // methods
  const handleInvite = useCallback(
    async (careCircleMemberInvite: CareCircleMemberInvite) => {
      try {
        const res = await inviteCareCircle(careCircleMemberInvite).unwrap();
        dispatch(newToast({ title: 'Email invite sent', variant: 'success' }));
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    [inviteCareCircle],
  );

  return (
    <CareCircleMemberAll
      careCircle={careCircle}
      onPreview={(member) => navigate(`/care-circle/member/${member.id}`)}
      onInvite={handleInvite}
      relationshipOptions={relationshipOptions || []}
    />
  );
};

export default CareCircleMember;
