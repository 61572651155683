export const completionStepsArray = [
    {
        icon: 'check-circle',
        text: 'Please complete all of the questions in the form',
        iconColor: 'success',
    },
    {
        icon: 'user',
        text: 'Head back using the "Back" button, or use the Sections to fill in the blanks',
        iconColor: 'blue',
    },
    {
        icon: 'file-contract',
        text: 'Return to submit this form once complete',
        iconColor: 'primary',
    },
];
