// react
import { useMemo } from 'react';

// store
import { useSelector } from 'store';
import {
  getCareCircleId,
  getCareCircleMember,
} from 'store/slices/careCircleSlice';
import { useGetCareCircleQuery } from 'store/api/careCircle';

// models
import { CareCircleMember } from '@karehero/models';

export const useCareCircle = () => {
  // state
  const careCircleId = useSelector(getCareCircleId);
  const { data: careCircle } = useGetCareCircleQuery(careCircleId, {
    skip: !careCircleId,
  });
  const careCircleMember: CareCircleMember = useSelector(getCareCircleMember);

  // memo
  const primaryCareCircleMember = useMemo(
    () =>
      careCircle &&
      careCircle.careCircleMembers?.find(
        (m) => m.account.id === careCircle?.primaryCaregiverAccount.id,
      ),
    [careCircle],
  );

  return {
    careCircle,
    careCircleMember,
    primaryCareCircleMember,
  };
};
