import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
// radix
import * as RadixSwitch from '@radix-ui/react-switch';
import { BaseField } from "..";
import { Icon } from "../Icon/Icon";
import { Label } from "../Label/Label";
/**
 * Switch allows the user to toggle between two states.
 */
export const Switch = ({ id, description, isDisabled = false, label, size = 'md', value, onChange, isRequired, isNoWrap, }) => {
    return (_jsx(BaseField, Object.assign({ id: id, description: description, isHideTitle: true }, { children: _jsxs(Container, { children: [label && (_jsx(StyledLabel, { id: id, text: label, isRequired: isRequired, "$isNoWrap": isNoWrap })), _jsx(StyledSwitchRoot, Object.assign({ id: id, disabled: isDisabled, size: size, checked: value, onCheckedChange: onChange }, { children: _jsx(StyledSwitchThumb, Object.assign({ "$isDisabled": isDisabled, "$size": size }, { children: _jsx(Icon, { icon: 'check', fontSize: size === 'md' ? 16 : 14 }) })) }))] }) })));
};
const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledSwitchRoot = styled(RadixSwitch.Root) `
  all: unset;
  position: relative;
  box-sizing: border-box;
  border-radius: 9999px;
  cursor: pointer;

  ${(props) => {
    switch (props.size) {
        case 'sm':
            return css `
          width: 72px;
          height: 34px;
        `;
        case 'md':
            return css `
          width: 88px;
          height: 44px;
        `;
    }
}}

  border: 1px solid ${(props) => props.theme.color.neutral[50]};
  background: ${(props) => props.theme.color.neutral[30]};

  &:hover {
    border: 1px solid ${(props) => props.theme.color.primary[40]};
    background: ${(props) => props.theme.color.neutral[40]};
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.color.primary[50]};
    outline-offset: 1px;
    border: 1px solid ${(props) => props.theme.color.primary[50]};
  }

  &[data-state='checked'] {
    background: ${(props) => props.theme.color.primary[40]};
    border: 1px solid ${(props) => props.theme.color.primary[70]};

    &:hover {
      background: ${(props) => props.theme.color.primary[60]};
      border: 1px solid ${(props) => props.theme.color.primary[70]};
    }

    &:focus-visible {
      background: ${(props) => props.theme.color.primary[60]};
    }
  }

  &:disabled {
    pointer-events: none;
    background: ${(props) => props.theme.color.neutral[40]};
    border-color: ${(props) => props.theme.color.neutral[40]};
  }
`;
const StyledSwitchThumb = styled(RadixSwitch.Thumb) `
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 9999px;

  ${(props) => {
    switch (props.$size) {
        case 'sm':
            return css `
          width: 26px;
          height: 26px;
          transform: translateX(4px);
          &[data-state='checked'] {
            transform: translateX(40px);
          }
        `;
        case 'md':
            return css `
          width: 32px;
          height: 32px;
          transform: translateX(8px);
          &[data-state='checked'] {
            transform: translateX(48px);
          }
        `;
    }
}}

  background: ${(props) => props.theme.color.neutral[10]};
  color: ${(props) => props.theme.color.primary[50]};
  border: 1px solid ${(props) => props.theme.color.neutral[50]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  transition: transform 100ms;
  will-change: transform;

  & > svg {
    transition-duration: 100ms;
    opacity: 0;
  }

  &[data-state='checked'] {
    ${(props) => props.$isDisabled
    ? css `
            border: 1px solid ${props.theme.color.neutral[40]};
          `
    : css `
            border: 1px solid ${props.theme.color.primary[70]};
          `}
    & > svg {
      opacity: 1;
    }
  }

  ${(props) => props.$isDisabled &&
    css `
      color: ${props.theme.color.neutral[50]};
      pointer-events: none;
      background: ${props.theme.color.neutral[40]};
      border-color: ${props.theme.color.neutral[40]};
    `}
`;
const StyledLabel = styled(Label) `
  ${(props) => props.$isNoWrap &&
    css `
      white-space: nowrap;
    `}
`;
Switch.displayName = 'Switch';
export default Switch;
