// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// store
import { setCareCircleId } from 'store/slices/careCircleSlice';
import { useCheckEmailMutation } from 'store/api/iam';

// components
import { SignIn as SignInPage } from '@karehero/llama';

// hooks
import { useKinde } from 'hooks';

/**
 * SignIn is the sign in page, and handles user authentication.
 */
const SignIn = () => {
  // hooks
  const dispatch = useDispatch();
  const { signIn, ConnectionID } = useKinde();
  const [searchParams] = useSearchParams();
  const [checkEmail] = useCheckEmailMutation();
  const [isEmailSignInDisabled, setIsEmailSignInDisabled] = useState(false);

  // memos
  const isProduction = useMemo(
    () =>
      process.env.REACT_APP_BUILD_ENVIRONMENT === 'production'
        ? true
        : undefined,
    [],
  );

  // methods
  const handleEmailSignIn = useCallback(
    async (email: string) => {
      setIsEmailSignInDisabled(true);
      const res = await checkEmail(email);
      if ('error' in res) {
        console.error(res.error);
        setIsEmailSignInDisabled(false);
        return;
      }
      signIn(ConnectionID.EMAIL, email);
    },
    [signIn, ConnectionID, checkEmail],
  );

  // effects
  useEffect(() => {
    dispatch(setCareCircleId(undefined));
  }, [dispatch]);

  return (
    <SignInPage
      onGoogleSignIn={isProduction && (() => signIn(ConnectionID.GOOGLE))}
      onFacebookSignIn={isProduction && (() => signIn(ConnectionID.FACEBOOK))}
      onAppleSignIn={isProduction && (() => signIn(ConnectionID.APPLE))}
      onEmailSignIn={handleEmailSignIn}
      isEmailSignInDisabled={isEmailSignInDisabled}
      message={searchParams.get('message') || undefined}
    />
  );
};

export default SignIn;
