// react
import { useEffect } from 'react';

// hooks
import { useCareProfileForm } from 'hooks';

// components
import {
  CareProfileForm as CareProfileFormPage,
  CareProfileRoot,
} from '@karehero/llama';

/**
 * AttendanceAllowanceForm is the dashboard page.
 */
const AttendanceAllowanceForm = () => {
  // hooks
  const {
    category,
    form,
    trackViewed,
    answers,
    sections,
    careRecipientFirstName,
    setAnswers,
    toPrevious,
    title,
    permission,
    handleSave,
  } = useCareProfileForm({
    categoryID: 'attendance-allowance',
  });

  // effects
  useEffect(trackViewed, [trackViewed]);

  return (
    <CareProfileRoot
      nextLabel='Save and Continue'
      limit={category?.progress?.requiredCount || 0}
      progress={category?.progress?.answeredCount || 0}
      sections={sections}
      name={careRecipientFirstName}
      title={title}
      toExit={`/${category?.id}`}
      onNext={handleSave}
      toPrevious={toPrevious}
    >
      <CareProfileFormPage
        form={form}
        value={answers}
        onChange={setAnswers}
        careRecipientName={careRecipientFirstName}
        isDisabled={permission.isModify}
        isAiHelper
      />
    </CareProfileRoot>
  );
};

export default AttendanceAllowanceForm;
