// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// models
import { FieldType } from '@karehero/models';

// components
import { EditorPage } from '@karehero/llama';

// editor
import { createFieldHelper } from '@karehero/llama';

// store
import { Account } from '@karehero/models';
import { useCreateAccountMutation } from 'store/api/account';
import { useGetAllAccountRoleQuery } from 'store/api/accountRole';
import { useGetAllOrganizationQuery } from 'store/api/organization';

/**
 * NewCareCircle is the dashboard page.
 */
const NewCareCircle = () => {
  // state
  const [account, setAccount] = useState<Partial<Account>>({});

  // hooks
  const navigate = useNavigate();
  const [createAccount] = useCreateAccountMutation();
  const { data: accountRoles, isLoading: isAccountRolesLoading } =
    useGetAllAccountRoleQuery();
  const { data: organizations } = useGetAllOrganizationQuery();

  useEffect(() => {
    if (isAccountRolesLoading) return;

    const defaultRole = accountRoles?.find(
      (accountRole) => accountRole.name === 'Organization Owner',
    );

    if (!defaultRole) return;

    setAccount((prev) => ({ ...prev, accountRoleID: defaultRole.id }));
  }, [isAccountRolesLoading, accountRoles]);

  // choices

  const fields = useMemo(() => {
    return createFieldHelper<Account>({
      firstName: {
        label: 'First Name',
        field: {
          type: FieldType.Text,
        },
      },
      lastName: {
        label: 'Last Name',
        field: {
          type: FieldType.Text,
        },
      },
      email: {
        label: 'Email',
        field: {
          type: FieldType.Text,
        },
      },
      accountRoleID: {
        label: 'Account Role',
        isRequired: true,
        field: {
          type: FieldType.Select,
          options: (accountRoles || [])
            .filter((accountRole) => accountRole.name !== 'Member')
            .filter((accountRole) => accountRole.name !== 'Care Concierge')
            .map((accountRole) => ({
              label: accountRole.name,
              value: accountRole.id,
            })),
        },
      },
      organizationID: {
        label: 'Organization',
        isRequired: true,
        field: {
          type: FieldType.Select,
          options: (organizations || []).map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        },
      },
    });
  }, [organizations, accountRoles]);

  // methods
  const handleSave = useCallback(async () => {
    const res = await createAccount(account as Account);
    if (!('data' in res)) {
      return;
    }

    navigate('/admin/account/manage');
    return;
  }, [navigate, createAccount, account]);

  return (
    <EditorPage
      title={'New Account'}
      description='Create a new account'
      fields={fields}
      value={account}
      onChange={setAccount}
      saveLabel='Save'
      cancelLabel='Close'
      onSave={handleSave}
      onCancel={() => navigate('/admin/account/manage')}
    />
  );
};

export default NewCareCircle;
