import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
// components
import { RadioGroup } from "..";
/**
 * Boolean allows the user to select a boolean value.
 */
export const Boolean = ({ id, label, description, tooltip, value, onChange, isDisabled = false, isRequired = false, isHideTitle = false, ariaLabel, }) => {
    const valueString = useMemo(() => {
        if (value === true || value === 'true') {
            return 'true';
        }
        if (value === false || value === 'false') {
            return 'false';
        }
        return '';
    }, [value]);
    return (_jsx(RadioGroup, { id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, ariaLabel: ariaLabel, isHideTitle: isHideTitle, isDisabled: isDisabled, maxColumns: 2, options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ], value: valueString, onChange: (val) => {
            if (val === 'true') {
                onChange === null || onChange === void 0 ? void 0 : onChange(true);
            }
            else if (val === 'false') {
                onChange === null || onChange === void 0 ? void 0 : onChange(false);
            }
            else {
                onChange === null || onChange === void 0 ? void 0 : onChange(undefined);
            }
            onChange === null || onChange === void 0 ? void 0 : onChange(val === 'true');
        } }));
};
Boolean.displayName = 'Boolean';
export default Boolean;
