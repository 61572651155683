// react
import { useCallback, useEffect, useMemo, useState } from 'react';

// components
import { RecipientProfileAbout } from '@karehero/llama';

// hooks
import { useCareProfile, usePermissions } from 'hooks';

/**
 * KeyInformation shows the KeyInformationPage component.
 */
const KeyInformation = () => {
  // state
  const [flatAnswers, setFlatAnswers] = useState<any>({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // hooks
  const { careCircle, careProfileID, forms, answers, createManyAnswer } =
    useCareProfile({ categoryID: 'key-information' });
  const { carePlan } = usePermissions();

  // methods
  const handleSave = useCallback(() => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    createManyAnswer(flatAnswers);
    setIsEditing(false);
  }, [flatAnswers, isEditing, createManyAnswer]);

  // memos
  const careRecipient = useMemo(
    () => careCircle?.careRecipientAccount,
    [careCircle],
  );

  const keyInformationForm = useMemo(
    () => forms?.find((f) => f.id === 'key-information'),
    [forms],
  );

  const nextOfKinForm = useMemo(
    () => forms?.find((f) => f.id === 'next-of-kin'),
    [forms],
  );

  const professionalContactsForm = useMemo(
    () => forms?.find((f) => f.id === 'professional-contacts'),
    [forms],
  );

  // effects
  useEffect(() => {
    if (!isLoaded && careProfileID && Object.keys(answers).length > 0) {
      const careProfileAnswers: any = {};

      Object.keys(answers).forEach((key) => {
        careProfileAnswers[key] = answers[key];
      });

      setFlatAnswers(careProfileAnswers);
      setIsLoaded(true);
    }
  }, [answers, isLoaded, careProfileID]);

  return (
    <RecipientProfileAbout
      name={`${careRecipient?.firstName || ''}`}
      keyInformationForm={keyInformationForm?.fields}
      nextOfKinForm={nextOfKinForm?.fields}
      professionalContactsForm={professionalContactsForm?.fields}
      answers={flatAnswers}
      setAnswers={setFlatAnswers}
      onSave={handleSave}
      isEditing={isEditing}
      isModify={carePlan.isModify}
    />
  );
};

export default KeyInformation;
