import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// models
import { FieldType } from '@karehero/models';
// components
import { Editor } from "../../organisms/Editor/Editor";
import { createFieldHelper } from "../../organisms/EditorFields/fieldHelper";
const fields = createFieldHelper({
    firstName: {
        label: 'First name',
        field: {
            type: FieldType.Text,
        },
    },
    lastName: {
        label: 'Last name',
        field: {
            type: FieldType.Text,
        },
    },
    phoneNumber: {
        label: 'Phone number',
        field: {
            type: FieldType.Text,
        },
    },
    email: {
        label: 'Email',
        field: {
            type: FieldType.Text,
        },
    },
});
/**
 * ContactField allows you to add a contact field to your form.
 */
export const ContactField = ({ label, value, onChange }) => {
    // memo
    const contact = useMemo(() => {
        let c = {};
        try {
            c = JSON.parse(value || '{}');
        }
        catch (e) {
            console.error(e);
        }
        return c;
    }, [value]);
    return (_jsxs(Wrapper, { children: [_jsx(Title, { children: label }), _jsx(Editor, { fields: fields, value: contact, onChange: (val) => onChange(JSON.stringify(val)), isFloatingActions: false, isActionsDisabled: true, isForm: false })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.primary[90]};
`;
ContactField.displayName = 'ContactField';
export default ContactField;
