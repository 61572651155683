// react
import { useCallback } from 'react';

// hooks
import { FormIntro as FinancialStatusStartPage } from '@karehero/llama';
import { useCareProfile } from 'hooks';
import { useStartCareProfileFormMutation } from 'store/api/careProfile';

/**
 *  FinancialStatusStart is the page displayed for starting the financial status process.
 */
const FinancialStatusStart = () => {
  // hooks
  const { careCircle, toFirstFormPage } = useCareProfile({
    categoryID: 'financial-status',
  });

  const [startCareProfileForm] = useStartCareProfileFormMutation();

  // method
  const handleNext = useCallback(() => {
    careCircle?.careProfile?.id &&
      startCareProfileForm({
        careProfileCategoryId: 'financial-status',
        careProfileId: careCircle.careProfile.id,
        actionType: 'started',
      });
  }, [careCircle, startCareProfileForm]);

  return (
    <FinancialStatusStartPage
      introPageItems={[
        {
          icon: 'file-contract',
          text: 'Answer financial questions about the person requiring care',
          iconColor: 'blue' as 'blue',
        },
        {
          icon: 'check-circle',
          text: 'Our experts will review and calculate your bespoke funding options',
          iconColor: 'success' as 'success',
        },
        {
          icon: 'home',
          text: "We'll send you a My Funding Guide and help you apply for funding/benefits, if relevant",
          iconColor: 'primary' as 'primary',
        },
      ]}
      introCardDetails={
        "We've helped thousands of families claim funding they didn't realise they were eligible for"
      }
      title={'Get bespoke consultation on how to fund care'}
      toAssistance={'/book-call'}
      toForm={toFirstFormPage}
      onNext={handleNext}
    />
  );
};

FinancialStatusStart.displayName = 'FinancialStatusStart';
export default FinancialStatusStart;
