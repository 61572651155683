// react
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

// components
import { Dashboard as DashboardPage } from '@karehero/llama';

// hooks
import { useActionPlan } from 'hooks';

// store
import { useDispatch } from 'react-redux';
import { setCareCircleId } from 'store/slices/careCircleSlice';
import {
  useBookmarkArticleMutation,
  useGetAllArticleBookmarkQuery,
  useGetAllSuggestedArticleQuery,
} from 'store/api/cms';
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

/**
 * Dashboard is the dashboard page.
 */
const Dashboard = () => {
  // hooks
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    actionPlanItemsTodo,
    allActionPlanItems,
    markActionDismissed,
    actionPlanCategories,
  } = useActionPlan();
  const { data: articleBookmarks } = useGetAllArticleBookmarkQuery();
  const { data: suggestedArticles, isLoading: isArticlesLoading } =
    useGetAllSuggestedArticleQuery();
  const [bookmarkArticle] = useBookmarkArticleMutation();
  const account = useSelector(getAccount);

  // memos
  const computedSuggestedArticles = useMemo(
    () => ({
      articles: suggestedArticles,
      bookmarkedArticles: articleBookmarks,
      onBookmark: (article: any) => bookmarkArticle(article._id),
      isLoading: isArticlesLoading,
    }),
    [articleBookmarks, suggestedArticles, bookmarkArticle, isArticlesLoading],
  );

  // effects
  useEffect(() => {
    const careCircleId = searchParams.get('careCircleId');
    if (!careCircleId) {
      return;
    }

    // check if valid uuid
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (!uuidRegex.test(careCircleId)) {
      return;
    }

    dispatch(setCareCircleId(careCircleId));
  }, [dispatch, searchParams]);

  return (
    <DashboardPage
      suggestedArticles={computedSuggestedArticles}
      actionPlanItems={actionPlanItemsTodo}
      allActionPlanItems={allActionPlanItems}
      onActionPlanItemDismiss={markActionDismissed}
      account={account}
      actionPlanCategories={actionPlanCategories}
    />
  );
};

export default Dashboard;
