// react
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  localStorageMiddleware,
  mixpanelTrackingMiddleware,
  preloadState,
} from './middleware';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';

// store
import api from './api';
import iamReducer from './slices/iamSlice';
import careCircleReducer from './slices/careCircleSlice';
import careProfileReducer from './slices/careProfileSlice';
import navigationReducer from './slices/navigationSlice';
import toastReducer from './slices/toastSlice';
import cmsReducer from './slices/cmsSlice';

// reducers
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  toast: toastReducer,
  iam: iamReducer,
  careCircle: careCircleReducer,
  careProfile: careProfileReducer,
  navigation: navigationReducer,
  cms: cmsReducer,
});

// create store
const store = configureStore({
  reducer: rootReducer,
  preloadedState: preloadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(mixpanelTrackingMiddleware)
      .concat(localStorageMiddleware),
  devTools: process.env.nodeEnv !== 'production',
});

// types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

// hooks
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
export const { dispatch } = store;

// invalidate tags
const invalidateTimeout = 6 * 60 * 60 * 1000; // 6 hours
dispatch(api.util.invalidateTags(['HubspotIdentity']));
setInterval(
  () => dispatch(api.util.invalidateTags(['HubspotIdentity'])),
  invalidateTimeout,
);
