import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import { Button } from "../../atoms/Button/Button";
import { EditorFields } from "../EditorFields/EditorFields";
/**
 * validationAsString converts a validation array to a string.
 */
export const validationAsString = (validations) => {
    if (!validations) {
        return undefined;
    }
    const validationStrings = validations.map((validation) => {
        let valueString;
        switch (typeof validation.value) {
            case 'string':
                valueString = validation.value;
                break;
            case 'number':
                valueString = validation.value.toString();
                break;
            case 'boolean':
                valueString = validation.value ? 'true' : 'false';
                break;
            case 'object':
                if (Array.isArray(validation.value)) {
                    valueString = validation.value.join(',');
                    break;
                }
                valueString = validation.value === null ? 'null' : '';
                break;
            case 'undefined':
                valueString = '';
                break;
            default:
                throw new Error('Unsupported validation value type');
        }
        return `${validation.type}:${valueString}`;
    });
    return validationStrings.join(',');
};
/**
 * Editor enables users to input information which will be updated in the
 * database.
 */
export const Editor = ({ onSave, onCancel, saveLabel = 'Save', cancelLabel = 'Cancel', isWithArrows = false, isActionsDisabled = false, value, onChange, fields, isDisabled = false, isFloatingActions = true, isForm = true, isSaveDisabled = false, }) => {
    // methods
    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        onSave === null || onSave === void 0 ? void 0 : onSave();
    }, [onSave]);
    const EditorComponent = useMemo(() => (_jsxs(_Fragment, { children: [_jsx(EditorFields, { fields: fields, value: value, onChange: onChange, isDisabled: isDisabled }), isFloatingActions && _jsx(ActionButtonsPlaceholder, {}), !isActionsDisabled && (_jsxs(EditorActions, Object.assign({ "$isFloatingActions": isFloatingActions }, { children: [_jsx(Button, Object.assign({ ariaLabel: cancelLabel, onPress: onCancel, variant: 'secondary', iconLeft: isWithArrows ? 'long-arrow-left' : undefined }, { children: cancelLabel })), _jsx(Button, Object.assign({ type: 'submit', ariaLabel: saveLabel, 
                        // onPress={onSave}
                        iconRight: isWithArrows ? 'long-arrow-right' : undefined, isDisabled: isSaveDisabled }, { children: saveLabel }))] })))] })), [
        fields,
        value,
        onChange,
        isFloatingActions,
        isActionsDisabled,
        isWithArrows,
        cancelLabel,
        saveLabel,
        onCancel,
    ]);
    return isForm ? (_jsx(StyledEditor, Object.assign({ onSubmit: handleSubmit }, { children: EditorComponent }))) : (_jsx(StyledEditorDiv, { children: EditorComponent }));
};
const StyledEditor = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[32]};
  width: 100%;
`;
const StyledEditorDiv = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[32]};
  width: 100%;
`;
const EditorActions = styled.div `
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;

  ${(props) => !props.$isFloatingActions &&
    css `
      & > button {
        flex: 1;
      }
    `};
  ${(props) => props.$isFloatingActions &&
    css `
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      gap: ${props.theme.spacing[16]};
      padding: ${props.theme.spacing[16]} 0;
      border-top: 1px solid ${props.theme.color.neutral[50]};
      background: ${props.theme.color.neutral[10]};
    `}
`;
const ActionButtonsPlaceholder = styled.div `
  width: 100%;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
`;
Editor.displayName = 'Editor';
export default Editor;
