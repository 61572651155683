var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Icon } from "../Icon";
/**
 * SupportingText provides stylised helper text for form components.
 */
export const SupportingText = (_a) => {
    var { className, text, icon = 'exclamation-circle', size = 'sm' } = _a, props = __rest(_a, ["className", "text", "icon", "size"]);
    return (_jsxs(StyledContainer, Object.assign({ className: className, "$size": size }, props, { children: [_jsx(StyledIcon, { icon: icon, fontSize: sizeMap[size].iconSize }), _jsx(StyledSupportingText, Object.assign({ "$size": size }, { children: text }))] })));
};
const StyledContainer = styled.div `
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[sizeMap[props.$size].spacing]};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const StyledSupportingText = styled.span `
  // default styles
  font: ${(props) => props.theme.typography.body[sizeMap[props.$size].fontSize].regular};
`;
const sizeMap = {
    sm: {
        fontSize: 'xs',
        iconSize: 12,
        spacing: 8,
    },
    md: {
        fontSize: 'sm',
        iconSize: 20,
        spacing: 12,
    },
};
const StyledIcon = styled(Icon) `
  padding-top: 3px;
`;
SupportingText.componentName = 'SupportingText';
export default SupportingText;
