import api from '.';
import {
  CareProfileAnswer,
  CareProfileCategory,
  CareProfileAnswerRequest,
  CareProfileSubmission,
} from '@karehero/models';

export const careProfileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategoryByProfile: builder.query<CareProfileCategory[], string>({
      query: (id) => `/care-profile/${id}/category`,
      providesTags: ['CareProfileCategories'],
      keepUnusedDataFor: 0,
    }),
    getCareProfileSubmission: builder.query<
      CareProfileSubmission,
      CareProfileSubmission
    >({
      query: (careProfileSubmission: CareProfileSubmission) => ({
        url: `care-profile/${careProfileSubmission.careProfileId}/category/${careProfileSubmission.careProfileCategoryId}/submission`,
        method: 'GET',
      }),
    }),
    createManyAnswer: builder.mutation<
      CareProfileAnswer[],
      CareProfileAnswerRequest
    >({
      query: (body: CareProfileAnswerRequest) => ({
        url: `care-profile/${body.careProfileId}/answer`,
        method: 'POST',
        body: body.answers,
      }),
      invalidatesTags: ['CareProfileCategories', 'CareCircles'],
    }),
    submitCareProfileForm: builder.mutation<void, CareProfileSubmission>({
      query: (careProfileSubmission: CareProfileSubmission) => ({
        url: `/care-profile/${careProfileSubmission.careProfileId}/category/${careProfileSubmission.careProfileCategoryId}/submit`,
        method: 'POST',
      }),
    }),
    startCareProfileForm: builder.mutation<void, CareProfileSubmission>({
      query: (careProfileSubmission: CareProfileSubmission) => ({
        url: `/care-profile/${careProfileSubmission.careProfileId}/category/${careProfileSubmission.careProfileCategoryId}/start`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetAllCategoryByProfileQuery,
  useGetCareProfileSubmissionQuery,
  useCreateManyAnswerMutation,
  useSubmitCareProfileFormMutation,
  useStartCareProfileFormMutation,
} = careProfileApi;

export default careProfileApi;
