// react
import { useMemo } from 'react';
// components
import {
  Loading as LoadingPage,
  Messenger as MessengerPage,
} from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';
import { useGetHubspotIdentificationTokenQuery } from 'store/api/iam';

// sentry
import * as Sentry from '@sentry/react';

/**
 * Chat is the dashboard page.
 */
const Chat = () => {
  // hooks
  const account = useSelector(getAccount);
  const { data: hubspotIdentificationToken } =
    useGetHubspotIdentificationTokenQuery();

  // memos
  const isHubspot = useMemo(
    () =>
      account?.kindeEmail &&
      hubspotIdentificationToken &&
      hubspotIdentificationToken?.token !== '' &&
      hubspotIdentificationToken?.token !== 'invalid',
    [account, hubspotIdentificationToken],
  );

  if (!isHubspot) {
    Sentry.captureException(
      new Error(
        `Invalid or empty HubSpot Identification Token for email: ${account?.kindeEmail}`,
      ),
    );
    return <LoadingPage />;
  }

  return (
    <MessengerPage
      kindeEmail={account?.kindeEmail}
      hubspotIdentificationToken={hubspotIdentificationToken?.token}
    />
  );
};

export default Chat;
