// react
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// models
import { FieldType } from '@karehero/models';

// components
import { EditorPage, JourneyPage } from '@karehero/llama';

// editor
import { createFieldHelper } from '@karehero/llama';

// store
import { Account, SetupCareCircle } from '@karehero/models';
import { useCreateMutation } from 'store/api/careCircle';

export const fields = createFieldHelper<Account>({
  firstName: {
    label: 'First Name',
    field: {
      type: FieldType.Text,
    },
  },
  lastName: {
    label: 'Last Name',
    field: {
      type: FieldType.Text,
    },
  },
  email: {
    label: 'Email',
    field: {
      type: FieldType.Text,
    },
  },
});

enum Steps {
  PrimaryCaregiver = 0,
  Journey = 1,
  CareRecipient = 2,
}

/**
 * NewCareCircle is the dashboard page.
 */
const NewCareCircle = () => {
  // state
  const [activeStep, setActiveStep] = useState(Steps.PrimaryCaregiver);
  const [primaryCaregiver, setPrimaryCaregiver] = useState<Partial<Account>>(
    {},
  );
  const [careRecipient, setCareRecipient] = useState<Partial<Account>>({});

  // hooks
  const navigate = useNavigate();
  const [createCareCircle] = useCreateMutation();

  // methods
  const handleSave = useCallback(() => {
    // if the primary caregiver is complete, then we can create the care circle
    if (activeStep === Steps.CareRecipient) {
      (async () => {
        const newCareCircle: SetupCareCircle = {
          primaryCaregiverAccount: primaryCaregiver as Account,
          careRecipientAccount: careRecipient as Account,
        };
        const res = await createCareCircle(newCareCircle);
        if (!('data' in res)) {
          return;
        }
      })();
      navigate('/admin/care-circle/manage');
    }
  }, [activeStep, navigate, createCareCircle, primaryCaregiver, careRecipient]);

  switch (activeStep) {
    case Steps.PrimaryCaregiver:
      return (
        <EditorPage
          title={'New Care Circle'}
          description='Primary Caregiver Details'
          fields={fields}
          value={primaryCaregiver}
          onChange={setPrimaryCaregiver}
          saveLabel='Continue'
          cancelLabel='Close'
          onSave={() => setActiveStep(Steps.Journey)}
          onCancel={() => navigate('/admin/care-circle/manage')}
        />
      );
    case Steps.Journey:
      return (
        <JourneyPage
          title='Great, a new Care Profile has been created!'
          description={"Let's add the care recipient to the Care Circle"}
          actionLabel='Add Care Recipient'
          onAction={() => setActiveStep(Steps.CareRecipient)}
        />
      );
    case Steps.CareRecipient:
      return (
        <EditorPage
          title={'New Care Circle'}
          description='Care Recipient Details'
          fields={fields}
          value={careRecipient}
          onChange={setCareRecipient}
          saveLabel='Continue'
          cancelLabel='Close'
          onSave={handleSave}
          onCancel={() => setActiveStep(Steps.PrimaryCaregiver)}
        />
      );
    default:
      return <div>Loading</div>;
  }
};

export default NewCareCircle;
