// react
import { useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { Calendly as BookCallPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

const DEFAULT_CALENDLY_URL =
  'https://calendly.com/care-concierge-kh/care-concierge-initial-call';

const CARE_ASSESSMENT_CALENDLY_URL =
  'https://calendly.com/care-concierge-kh/care-assessment-call-with-care-expert';

const FUND_CONSULTAITON_CALENDLY_URL =
  'https://calendly.com/care-concierge-kh/funding-consultation-with-care-expert';

const ATTENDANCE_ALLOWANCE_CALENDLY_URL =
  'https://calendly.com/care-concierge-kh/attendance-allowance-help';

/**
 * BookCall shows the BookCallPage component.
 */
const BookCall = () => {
  // hooks
  const { track } = useMixpanel();
  const location = useLocation();
  const account = useSelector(getAccount);
  const [searchParams] = useSearchParams();

  const paramCategory = searchParams.get('category');

  const calendlyLink = useMemo(() => {
    switch (paramCategory) {
      case 'find-care':
        return CARE_ASSESSMENT_CALENDLY_URL;
      case 'cover-costs':
        return FUND_CONSULTAITON_CALENDLY_URL;
      case 'attendance-allowance':
        return ATTENDANCE_ALLOWANCE_CALENDLY_URL;
      default:
        return DEFAULT_CALENDLY_URL;
    }
  }, [paramCategory]);

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallViewed, {});
  }, [track]);

  return (
    <BookCallPage
      calendlyCallDetails={{
        ...location.state,
        name: account.firstName || '',
        kindeEmail: account.kindeEmail,
      }}
      isLoading={false}
      baseURL={calendlyLink}
    />
  );
};

export default BookCall;
