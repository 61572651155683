import api from '.';
import { InvitedMember } from '@karehero/models';

export const invitedMemberApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvitedMember: builder.query<InvitedMember[], void>({
      query: () => '/invite',
      providesTags: ['InvitedMembers'],
    }),
  }),
});

export const { useGetAllInvitedMemberQuery } = invitedMemberApi;

export default invitedMemberApi;
