var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
// components
import { Loader } from "../../atoms";
/**
 * HubSpotChat shows the HubSpot chat widget.
 */
export const HubSpotChat = ({ kindeEmail, token }) => {
    // state
    const [isLoaded, setIsLoaded] = useState(false);
    // refs
    const initialLoadRef = useRef(true);
    // memos
    const hubspotTrackingURL = useMemo(() => {
        var _a, _b;
        const env = (_a = process.env.REACT_APP_BUILD_ENVIRONMENT) !== null && _a !== void 0 ? _a : 'local';
        const urls = {
            local: 'https://js-eu1.hs-scripts.com/142770276.js',
            dev: 'https://js-eu1.hs-scripts.com/142770276.js',
            staging: 'https://js-eu1.hs-scripts.com/142770276.js',
            production: 'https://js-eu1.hs-scripts.com/139578958.js',
        };
        return (_b = urls[env]) !== null && _b !== void 0 ? _b : urls['local'];
    }, []);
    // methods
    const loadHubSpot = useCallback((tkn) => {
        if (!tkn)
            return;
        const scriptElement = document.createElement('script');
        scriptElement.src = hubspotTrackingURL !== null && hubspotTrackingURL !== void 0 ? hubspotTrackingURL : '';
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.addEventListener('load', () => {
            window.hsConversationsSettings = Object.assign(Object.assign({}, window.hsConversationsSettings), { identificationEmail: kindeEmail, identificationToken: tkn, inlineEmbedSelector: '#hubspot-chat', loadImmediately: false });
        });
        scriptElement.addEventListener('error', () => {
            console.error('Error loading HubSpot script');
        });
        document.body.appendChild(scriptElement);
        return scriptElement;
    }, []);
    const waitForWidget = useCallback((callback) => {
        setTimeout(() => {
            var _a;
            if ((_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget) {
                callback();
                return;
            }
            waitForWidget(callback);
        }, 20);
    }, []);
    const refreshChatToken = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!token)
            return;
        window.hsConversationsSettings = Object.assign(Object.assign({}, window.hsConversationsSettings), { identificationToken: token });
        waitForWidget(() => {
            var _a, _b, _c, _d;
            // remove
            (_b = (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget) === null || _b === void 0 ? void 0 : _b.remove();
            // load
            (_c = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _c === void 0 ? void 0 : _c.on('widgetLoaded', () => {
                setIsLoaded(true);
            });
            (_d = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _d === void 0 ? void 0 : _d.widget.load();
        });
    }), [token, waitForWidget]);
    // effects
    useEffect(() => {
        let scriptElement;
        if (!token)
            return;
        if (initialLoadRef.current) {
            initialLoadRef.current = false;
            scriptElement = loadHubSpot(token);
        }
        return () => {
            var _a, _b, _c, _d;
            if ((window === null || window === void 0 ? void 0 : window.HubSpotConversations) &&
                ((_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.clear) &&
                ((_b = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _b === void 0 ? void 0 : _b.on)) {
                (_c = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _c === void 0 ? void 0 : _c.on('widgetLoaded', () => { });
                (_d = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _d === void 0 ? void 0 : _d.clear({ resetWidget: true });
            }
            if (scriptElement) {
                scriptElement.remove();
            }
        };
    }, [token]);
    useEffect(() => {
        if (!initialLoadRef.current)
            refreshChatToken();
    }, [initialLoadRef.current, refreshChatToken]);
    const Global = createGlobalStyle ``;
    return (_jsxs(_Fragment, { children: [_jsx(Global, {}), _jsxs(Wrapper, { children: [_jsx(Chat, { id: 'hubspot-chat' }), !isLoaded && (_jsx(LoaderWrapper, { children: _jsx(Loader, {}) }))] })] }));
};
const Wrapper = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
`;
const Chat = styled.div `
  width: 100%;
  height: 100%;

  & #hubspot-conversations-inline-parent,
  & iframe {
    border: none;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
  }
`;
const LoaderWrapper = styled.div `
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
HubSpotChat.displayName = 'HubSpotChat';
export default HubSpotChat;
