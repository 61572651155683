// react
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// hooks
import { useCareProfile } from 'hooks';

// components
import {
  AttendanceAllowanceHero as AttendanceAllowanceHeroPage,
  CareProfileRoot,
  EligibilityCalculator,
} from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * AttendanceAllowanceStart is the dashboard page.
 */
const AttendanceAllowanceStart = () => {
  // hooks
  const {
    sections: aaSections,
    category: aaCategory,
    toFirstFormPage: aaToFirstFormPage,
    careRecipientFirstName,
    title: aaTitle,
  } = useCareProfile({ categoryID: 'attendance-allowance' });

  const {
    createManyAnswer: eligibilityCreateManyAnswer,
    trackSubmitted: eligibilityTrackSubmitted,
    answers: eligibilityAnswers,
    setAnswers: eligibilitySetAnswers,
    forms: eligibilityForms,
  } = useCareProfile({
    categoryID: 'aa-eligibility',
  });

  const navigate = useNavigate();
  const { track } = useMixpanel();

  // callbacks
  const onEligibilityStatusChange = useCallback(
    (status: string) => {
      if (status !== 'incomplete') {
        track(MixpanelEvent.AttendanceAllowanceEligibilityResult, { status });
      }
    },
    [track],
  );

  const handleUpdateCareProfile = useCallback(async (): Promise<boolean> => {
    const res = await eligibilityCreateManyAnswer(eligibilityAnswers);
    if (!('data' in res)) {
      console.error('Failed to update care profile', res.error);
      return false;
    }

    eligibilityTrackSubmitted();

    return true;
  }, [
    eligibilityAnswers,
    eligibilityTrackSubmitted,
    eligibilityCreateManyAnswer,
  ]);

  return (
    <StyledCareProfileRoot
      nextLabel='Start form'
      limit={aaCategory?.progress?.requiredCount || 0}
      progress={aaCategory?.progress?.answeredCount || 0}
      sections={aaSections}
      name={careRecipientFirstName}
      title={aaTitle}
      toExit='/care-profile'
      onNext={() => navigate(aaToFirstFormPage)}
      toPrevious='/care-profile'
    >
      <AttendanceAllowanceHeroPage
        careRecipientName={careRecipientFirstName}
        eligibilityCalculator={
          <WrapperEligibilityCalculator>
            <EligibilityCalculator
              answers={eligibilityAnswers}
              setAnswers={eligibilitySetAnswers}
              fields={eligibilityForms?.[0]?.fields}
              onEligibilityStatusChange={onEligibilityStatusChange}
              onUpdateCareProfile={handleUpdateCareProfile}
            />
            <Banner>Eligibility calculator</Banner>
          </WrapperEligibilityCalculator>
        }
      />
    </StyledCareProfileRoot>
  );
};

const StyledCareProfileRoot = styled(CareProfileRoot)`
  overflow: hidden;
`;

const WrapperEligibilityCalculator = styled.div`
  position: relative;
  padding-top: ${(props) => props.theme.spacing[12]};
`;

const Banner = styled.div`
  background: ${(props) => props.theme.color.primary[80]};
  color: ${(props) => props.theme.color.neutral[10]};
  font: ${(props) => props.theme.typography.body.xxs.medium};
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[8]};
  position: absolute;
  top: 0;
  left: 3%;
  transform: translateX(-3%);
  border-radius: 0 0 6px 6px;
`;

export default AttendanceAllowanceStart;
