// react
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

// components
import { AttendanceAllowanceSuccess as AttendanceAllowanceSuccessPage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';
import { generatePdf } from 'pdf/attendanceAllowance';

/**
 * AttendanceAllowanceSuccess is the page displayed when the user has successfully submitted the Attendance Allowance form.
 */
const AttendanceAllowanceSuccess = () => {
  // hooks
  const navigate = useNavigate();

  const { careRecipientFirstName, careProfileID } = useCareProfile({
    categoryID: 'care-assessment',
  });

  // methods
  const handlePrintPdf = useCallback(() => {
    if (careProfileID) {
      generatePdf(careProfileID);
    }
  }, [careProfileID]);

  return (
    <AttendanceAllowanceSuccessPage
      toBack={() => navigate('/care-profile')}
      handlePrintPdf={handlePrintPdf}
      name={careRecipientFirstName ?? 'Your Loved One'}
    />
  );
};

export default AttendanceAllowanceSuccess;
